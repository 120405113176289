<script setup lang="ts">
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {ref} from "vue";
import {useVModel} from "@vueuse/core";

const modalBox = ref(null);

const emit = defineEmits(['update:modelValue', 'close', 'after-close','open','after-open']);
const props = defineProps({
    modelValue: Boolean || null,
    closable: {type: Boolean, default: true},
    backgroundColor: {default: 'bg-primary-900 bg-opacity-75'},
    size: {default: 'md'},
    height: {default: 'h-fit'},
    width: {default: 'w-full'},
    padding: {default: 'p-3 md:p-4'},
});

const open_modal = useVModel(props, 'modelValue', emit);

let width_modal = '';

switch (props.size) {
    case 'xs':
        width_modal = 'max-w-xl';
        break;
    case 'sm':
        width_modal = 'max-w-2xl';
        break;
    case 'md':
        width_modal = 'max-w-5xl';
        break;
    case 'lg':
        width_modal = 'max-w-7xl';
        break;
    case 'xl':
        width_modal = '';
        break;
    default:
        width_modal = 'max-w-5xl';
}

function closeModal() {
    changeModalStatus(false);
}

function toggleModal() {
    changeModalStatus(!open_modal.value);
}


function changeModalStatus(value: boolean) {
    if (!props.closable) {
        modalBox.value.classList.add('animate-fast-wiggle');
        setTimeout(() => {
            modalBox.value.classList.remove('animate-fast-wiggle');
        }, 100);
        return;
    }
    emit('update:modelValue', value)
    if (!value) {
        emit('close');
    }
}

function afterClose() {
    emit('after-close');
}
function afterOpen() {
    emit('after-open');
}

function emitOpen() {
    emit('open');
}

function emitClose() {
    emit('close');
}

</script>


<template>
    <TransitionRoot appear :show="open_modal" as="template">
        <Dialog as="div" @close="closeModal()" class="relative z-40">
            <TransitionChild @after-leave="afterClose()"
                             @afterEnter="afterOpen()"
                             @before-enter="emitOpen()"
                             @before-leave="emitClose()"
                             as="template"
                             enter="duration-300 ease-out"
                             enter-from="opacity-0"
                             enter-to="opacity-100"
                             leave="duration-200 ease-in"
                             leave-from="opacity-100"
                             leave-to="opacity-0"
            >
                <div :class="['fixed inset-0 w-full',{'bg-black/50': !$slots.background }]">
                    <slot name="background">

                    </slot>
                </div>
            </TransitionChild>

            <div :class="['fixed inset-0 overflow-y-auto w-screen',padding]" ref="modalBox">
                <div class="flex h-full items-center justify-center text-center">
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            :class="['transform rounded-sm bg-white px-3 py-1.5 md:py-2 md:px-4  flex flex-col ' +
                             'text-left align-middle shadow-xl transition-all m-auto', width_modal,width,height ]"
                        >
                            <DialogTitle
                                as="div"
                                class="md:text-lg text-base font-medium leading-6 text-gray-900 flex items-center justify-between select-none">


                                <slot name="title">

                                </slot>


                                <button class="cursor-pointer focus:outline-none ml-auto" @click="closeModal()">
                                    <slot name="close-icon" v-if="closable">
                                        <i class="fa-solid fa-xmark transition text-lg md:text-2xl py-0.5 px-2 hover:text-red-500"></i>
                                    </slot>
                                </button>

                            </DialogTitle>

                            <hr class="mt-0.5 md:mt-1">

                            <div class="mt-4 md:mt-5 grow h-full">
                                <slot name="content">
                                </slot>
                            </div>

                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
