<script setup lang="ts">
import {ref} from "vue";
import SidebarItem from "~templates/layouts/sidebar/sidebar-item.vue";
import {DsRoute} from "~/helper/DsRoute";
import {sidebar_open} from "~/composable/layout/sidebar";
import { useEventBus } from '@vueuse/core'
import {DsUser} from "~/helper/User/DsUser";

const props = defineProps<{
    name: string;
    icon: string;
    route: Array<{route: string[]; name: string; icon: string,role?:string}>
}>();

const open = ref(false);
const is_route = ref(false);


props.route.forEach((item) => {
    if(Array.isArray(item.route)){
        item.route.forEach((route_slug) => {
            if (DsRoute.is_route(route_slug)) {
                is_route.value = true;
                open.value = true;
            }
        });
    }else{
        if (DsRoute.is_route(item.route)) {
            is_route.value = true;
            open.value = true;
        }
    }
});

function listener(event: string, payload: string) {
    if(payload === props.name){
        return;
    }
    open.value = false;
}

const bus = useEventBus<string>('sidebar-category')
const unsubscribe = bus.on(listener)

function openCategory() {
    open.value = !open.value;
    bus.emit('sidebar-category', props.name)
}
</script>

<template>
    <div class="w-full flex flex-col group">
        <button type="button" @click="openCategory"
                :class="['flex pl-3 pr-2 py-1 transition group duration-250 cursor-pointer hover:bg-primary-200  relative ' +
                 'group-hover:bg-primary-100',
                {'bg-primary-500 group-hover:bg-primary-500 text-white':is_route,'hover:text-primary-950':!is_route}]">
            <i :class="[icon,'text-lg mr-3']"></i>
            <span class="flex w-full overflow-hidden my-auto ">
                <span class="my-auto text-xs" >{{ name }}</span>
                <i class="fa-solid fa-folder ml-auto my-auto" v-if="!open"></i>
                <i class="fa-regular fa-folder-open ml-auto my-auto" v-else></i>
            </span>
        </button>
            <div class="w-46 flex flex-col bg-gray-300/50  text-xs overflow-hidden" v-if="open" >
                <template v-for="item in route">
                <sidebar-item  :class="['transition',{'pl-5':sidebar_open}]" v-if="!item?.role || DsUser.haveRole(item.role)"
                              category :route="item.route" :name="item.name" :icon="item.icon" >
                </sidebar-item>
                </template>
            </div>
    </div>
</template>

<style scoped>

</style>
