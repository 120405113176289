<script setup lang="ts">
const emit = defineEmits(['update:modelValue']);

const {
    modelValue,
    classInput,
    classLabel,
    name,
    required,
    disabled,
    defaultOption
} = defineProps({
    modelValue: {default: null},
    classInput: {default: ''},
    classLabel: {default: ''},
    name: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    required: Boolean,
    disabled: Boolean,
    defaultOption: {type: Boolean, default: true},
});

const default_class = "w-full text-black py-2 px-4 bg-white rounded-sm border border-primary-300 outline-none transition\n" +
    "                    hover:border-primary focus:border-primary focus:ring-1 focus:ring-primary disabled:grayscale disabled:opacity-40 disabled:cursor-not-allowed"


</script>

<template>
    <label>
        <span :class="classLabel">
            <slot></slot> <span v-if="required">*</span>
        </span>
        <select
            :name="name"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            :class="[default_class,classInput]"
            :required="required"
            :disabled="disabled">

            <option selected disabled value="" v-if="defaultOption">-</option>
            <slot name="option">

            </slot>
        </select>
    </label>
</template>
