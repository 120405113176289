<script setup lang="ts">
import {DsUser} from "~/helper/User/DsUser";
import DsPophover from "~templates/components/pophover/ds-popover.vue";
import {Link} from "@inertiajs/vue3"
import {DsRoute} from "~/helper/DsRoute";
import DsToggle from "~templates/components/form/input/ds-toggle.vue";
import {useCookies} from "@vueuse/integrations/useCookies";
import {computed, ref, watch} from "vue";
import {PopoverGroup} from "@headlessui/vue";
import NotificationPopover from "~templates/layouts/notification-popover.vue";
import {haveUnreadNotifications, unreadNotifications} from "~/helper/Navigator/DsNotification";

const cookies = useCookies();
const pophoverGroup = ref(null);

const pophoverGroupWidth = computed(() => {
    if (pophoverGroup.value) {
        return pophoverGroup.value.$el.clientWidth -5;
    }
    return 0;
})

const no_cache = ref(cookies.get('p_helper_DsService_cache') === false);

watch(no_cache, (value) => {
    cookies.set('p_helper_DsService_cache', !value);
})
</script>

<template>
    <PopoverGroup ref="pophoverGroup" class="flex gap-3 md:gap-5 items-center w-full  grow ">
        <ds-pophover class-option="border border-t-0 border-primary bg-transparent mt-2
   bg-gradient-to-t from-white/90 from-75% to-white-500/50 left-0" :width="pophoverGroupWidth">
            <template #button="{open}">
                <div class="flex items-center justify-center relative">
                    <div :class="['w-7 text-xs mr-2 rounded-full text-white font-bold bg-red-500',!haveUnreadNotifications ? 'opacity-0' : '']">
                        {{ unreadNotifications.length }}
                    </div>
                    <div :class="[{'animate-wiggle-more animate-infinite animate-ease-in-out':haveUnreadNotifications && !open}]">
                        <span class="hidden">.</span>
                        <i class="fa-regular fa-bell text-base md:text-xl"></i>
                    </div>
                    <template v-if="haveUnreadNotifications">
                        <div class="h-2 w-2 bg-red-500 rounded-full absolute top-0 -right-1"></div>
                        <div class="h-2 w-2 bg-red-500 rounded-full absolute top-0 -right-1 animate-ping"></div>
                    </template>
                </div>
            </template>
            <template #option="{close}">
                <notification-popover></notification-popover>
            </template>
        </ds-pophover>
        <ds-pophover class-option="border border-t-0 border-primary bg-transparent
   bg-gradient-to-t from-white/90 from-75% to-white-500/50 mr-2 right-0" :width="pophoverGroupWidth">
            <template #button>
                <div class="text-center md:text-end cursor-pointer md:min-w-[10rem] justify-end mr-2 flex"
                     v-if="DsUser.isAuth()">
                    <div class="flex justify-content-center items-center gap-2">
                        <div :class="['flex gap-1 font-bold',{'text-red-500':DsUser.isImpersonating()}]">
                            <div class="flex items-center gap-1 text-xs md:text-sm">
                                <span>
                                    {{ DsUser.getUser('fullname') }}
                                </span>
                            </div>
                        </div>
                        <div class="relative">
                            <img :src="DsUser.getUser('avatar_pro')"
                                 :class="['object-cover h-7 w-7 md:h-10 md:w-10 rounded-full',{'border-2 border-red-500':DsUser.isImpersonating()}]"
                                 alt="user_avatar">
                        </div>
                        <img src="/images/icons/other-icon/spy.png" class="h-7 w-6" alt="SPY" v-if="DsUser.isImpersonating()">
                    </div>
                </div>
            </template>
            <template #option="{close}">
                <ul class="list-reset flex flex-col items-center">
                    <template v-if="DsUser.isAuth()">
                        <li class="w-full transition hover:bg-gray-100">
                            <Link :href="DsRoute.route('user.my-account')"
                                  class="p-2 flex text-gray-900 no-underline hover:underline items-center">
                                <i class="fa-solid fa-user mr-auto"></i><span>Mon compte</span>
                            </Link>
                        </li>
                        <li class="w-full transition hover:bg-red-100 text-red-600" v-if="!DsUser.isImpersonating()">
                            <Link href="/logout" class="p-2 flex no-underline hover:underline items-center">
                                <i class="fa-solid fa-person-running mr-auto"></i><span>Se déconnecter</span>
                            </Link>
                        </li>
                        <li class="w-full transition hover:bg-red-100 text-red-600" v-else>
                            <a :href="DsRoute.route('impersonate.leave')" class="p-2 flex  no-underline hover:underline items-center">
                                <img src="/images/icons/other-icon/spy.png" class="h-5 w-4 mr-auto" alt="SPY"><span>Bas les masques</span>
                            </a>
                        </li>
                    </template>
                    <template v-else>
                        <li>
                            <a href="/login" class="ps-2 block text-gray-900 no-underline hover:underline">Se
                                connecter</a>
                        </li>
                    </template>
                    <template v-if="DsUser.haveRole('cache.toggle')">
                        <hr>
                        <li class="w-full transition flex flex-col">
                            <hr class="my-2">
                            <ds-toggle color="bg-emerald-500 peer-checked:bg-red-500" v-model="no_cache">
                                <p>
                                    <span class="text-red-500" v-if="no_cache">Cache désactivé</span>
                                    <span class="text-emerald-500" v-else>Cache activé</span>
                                </p>
                            </ds-toggle>
                        </li>
                    </template>

                </ul>
            </template>
        </ds-pophover>
    </PopoverGroup>


</template>

<style scoped>

</style>
