<script setup lang="ts">
import {useVModel} from "@vueuse/core";
import {watch} from "vue";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: [Boolean, Number],
        default: false
    },
    color: {
        type: String,
        default: 'bg-gray-400 peer-checked:bg-blue-600'
    },
  disabled: {
        type: Boolean,
        default: false
    }
});

const modelValue = useVModel(props, 'modelValue', emit);
</script>

<template>
        <label :class="['relative inline-flex items-center cursor-pointer',{'opacity-50':disabled}]">
            <input type="checkbox" value="" class="sr-only peer" :disabled="disabled" :checked="modelValue" @change="$emit('update:modelValue',
            $event.target.checked)">
            <span :class="['w-11 h-6  rounded-full peer peer-focus:ring-1 peer-focus:ring-primary',
        'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[\'\']',
        ' after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5',
         'after:transition-all dark:border-gray-600',color]"></span>
            <span class="ms-3 text-sm font-medium text-gray-900 peer-checked:text-primary-600 transition select-none"><slot></slot></span>
        </label>
</template>

<style scoped>

</style>
