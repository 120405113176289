<script setup lang="ts">
import DsPophover from "~templates/components/pophover/ds-popover.vue";
import DsButton from "~templates/components/form/button/ds-button.vue";
import {defineAsyncComponent, ref} from "vue";

const DsModal = defineAsyncComponent(() => import('~templates/components/pophover/ds-modal.vue'));

const props = defineProps({
  classButton: {
    type: String || Object || Array,
    default: ''
  },
  classOption: {
    type: String || Object || Array,
    default: ''
  },
  submitOnYes: {
    type: Boolean,
    default: false
  },
  width: {
    string: String, default: 'w-52'
  },
  buttonCol: {
    string: Boolean, default: false
  },
  modal: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['click-yes', 'click-no']);

function actionYes() {
  emit('click-yes');
}

function actionNo() {
  emit('click-no');
}

const open = ref(false);
</script>

<template>
  <ds-pophover :class-button="`outline-none ${width}  ${classButton}`" v-if="!modal"
               :class-option="`border-t-0 border-2 ${classOption}`">
    <template #arrow>
      <span></span>
    </template>
    <template #button-prefix="{open}">
      <slot name="button-prefix" :open="open">

      </slot>
    </template>
    <template #button="{open}">
      <slot name="button" :open="open">

      </slot>
    </template>
    <template #option="{close}">
      <p class="text-center cursor-default text-xs md:text-sm">
        <slot name="text">
          Êtes-vous sûr ?
        </slot>
      </p>
      <div :class="['grid gap-4 mt-4',{'grid-cols-2':!buttonCol}]">
        <ds-button type="button" color="bg-red-500 hover:bg-red-400 py-3 uppercase" size="sm" @click="actionNo();close()">
          <slot name="no-text">
            Non
          </slot>
        </ds-button>
        <ds-button :type="submitOnYes ? 'submit' : 'button'" color="bg-emerald-500 hover:bg-emerald-400 py-3 uppercase" size="sm" @click="actionYes();close()">
          <slot name="yes-text">
            Oui
          </slot>
        </ds-button>
      </div>
    </template>
  </ds-pophover>
  <div :class="`outline-none  ${width}  ${classButton}`" v-else>
    <slot name="button-prefix" :open="open">

    </slot>
    <span @click="open = !open">
          <slot name="button" :open="open">

            </slot>
        </span>

    <ds-modal v-model="open" :class-option="`border-t-0 border-2 ${classOption}`" size="xs">
      <template #title>
        <p class="text-base md:text-lg xl:text-xl">Confirmation</p>
      </template>
      <template #content>
        <p class="text-center cursor-default text-sm md:text-base">
          <slot name="text">
            Êtes-vous sûr ?
          </slot>
        </p>
        <div :class="['grid gap-4 mt-4',{'grid-cols-2':!buttonCol}]">
          <ds-button type="button" color="bg-red-500 hover:bg-red-400 py-3 uppercase" size="sm" @click="actionNo();open = false;">
            <slot name="no-text">
              Non
            </slot>
          </ds-button>
          <ds-button :type="submitOnYes ? 'submit' : 'button'" color="bg-emerald-500 hover:bg-emerald-400 py-3 uppercase" size="sm"
                     @click="actionYes();open = false;">
            <slot name="yes-text">
              Oui
            </slot>
          </ds-button>
        </div>
      </template>
    </ds-modal>
  </div>
</template>

<style scoped>

</style>
