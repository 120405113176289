<script setup lang="ts">
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue'
import {useVModel} from "@vueuse/core";

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  classButton: {
    type: String,
  },
  classOption: {
    type: null,
  },
  width: {
    type: Number,
    default: null
  },
  static: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Boolean,
    default: false
  }
});

const open_pophover = useVModel(props, 'modelValue', emit);
</script>

<template>
  <Popover class="relative" v-slot="{ open }">
    <div class="flex items-center justify-center">
      <div class="">
        <slot name="button-prefix" :open="open">
        </slot>
      </div>
      <PopoverButton :class="[classButton,'focus:outline-none flex-1']" v-if="!static">
        <div class="w-full flex">
          <slot name="button" :open="open">
          </slot>
        </div>
        <slot name="arrow">
        </slot>
      </PopoverButton>
      <div :class="[classButton,'focus:outline-none flex-1']" v-else>

        <div class="w-full flex">
          <slot name="button" :open="open">
          </slot>
        </div>
        <slot name="arrow">
        </slot>
      </div>
    </div>

    <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel :class="['absolute z-10 bg-white/80 backdrop-blur-sm p-2 w-full rounded-b-md shadow-primary-950',classOption]"
                    v-slot="{ close }" :style="width ? `width:${width}px!important;` : ''" v-if="!static">
        <slot name="option" :close="close">
        </slot>
      </PopoverPanel>
      <PopoverPanel :class="['absolute z-10 bg-white/80 backdrop-blur-sm p-2 w-full rounded-b-md shadow-lg shadow-gray-500/40',classOption]"
                    v-slot="{ close }" :style="width ? `width:${width}px!important;` : ''" v-else-if="open_pophover" static>
        <slot name="option" :close="close">
        </slot>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<style scoped>

</style>
