<script setup lang="ts">
import DsConfirm from "~templates/components/form/input/ds-confirm.vue";
import Form from "~templates/pages/ticket/manage/form.vue";
import DsButton from "~templates/components/form/button/ds-button.vue";
import DsInput from "~templates/components/form/input/ds-input.vue";
import DsSelect from "~templates/components/form/input/ds-select.vue";
import {useVModel} from "@vueuse/core";
import DsModal from "~templates/components/pophover/ds-modal.vue";
import {
    addTicket,
    new_ticket_title,
    new_ticket_description,
    new_ticket_service
} from "~/pages/tickets/tickets";

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: Boolean,
});

const open_ticket = useVModel(props, 'modelValue',emit);
</script>

<template>
  <ds-modal v-model="open_ticket" size="sm">
    <template #title>
      Ajouter un ticket interne
    </template>
    <template #content>
      <form>
        <ds-input v-model="new_ticket_title" type="text" class="w-full mb-2" placeholder="Sujet"></ds-input>
        <ds-input v-model="new_ticket_description" type="textarea" class="w-full mb-2" placeholder="Ticket / Demande"></ds-input>
        <ds-select v-model="new_ticket_service" class-input="h-full">
          <template #option>
            <option value="buy">Service Achat</option>
            <option value="sav">Service Après-Vente</option>
            <option value="customer">Service Client</option>
            <option value="technical">Service Technique</option>
            <option value="computer">Service Informatique</option>
            <option value="logistics">Service Logistique</option>
            <option value="prepa">Service Prépa</option>
          </template>
        </ds-select>

        <ds-confirm class="mt-10 mx-auto" @click-yes="addTicket"
                    class-option="border-primary">
          <template #button="{ open }">
            <ds-button type="button" :class="{ 'rounded-b-none': open }" class-button="w-full">
              Ajouter
            </ds-button>
          </template>
        </ds-confirm>
      </form>
    </template>
  </ds-modal>
</template>
