<script setup lang="ts">
import {useVModel} from "@vueuse/core";

const emit = defineEmits(['update:modelValue']);
    const props = defineProps({
        modelValue: {
            type: Boolean,
            default: false
        }
    });

    const loading = useVModel(props, 'modelValue', emit);
</script>

<template>
    <div class="fixed top-0 left-0 h-screen w-screen bg-black/70 flex flex-col gap-2 items-center justify-center z-50" v-if="loading">
        <div class="w-32 h-32 animate-spin border-l-green-400 border-gray-400  text-sm border-4 rounded-full ">

        </div>
        <p class="text-center text-green-400 font-bold px-4 text-2xl  ">Chargement</p>
    </div>
</template>

<style scoped>

</style>
