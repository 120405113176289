<script setup lang="ts">
import {goToRoute} from "~/composable/layout/sidebar";
import {DsRoute} from "~/helper/DsRoute";
import {ref} from "vue";

const {route,name,icon,routeParam} = defineProps({
    route:  {
        type: [String, Array as () => Array<string>],
        default: [],
    },
    name: {
      type: String,
      default: DsRoute.route('homepage')
    },
    icon: {
        type: String,
        default: "fa-solid fa-house",
        required: false
    },
    routeParam:{
      type: Object,
      default: null,
    },
    category:{
        type: Boolean,
        default: false,
    }
});

const selected_route = Array.isArray(route) ? route[0] : route;

const selected = ref(false);


if(Array.isArray(route)){
    route.forEach(item => {
        if(DsRoute.current(item)){
            selected.value = true;
        }
    });
}else if(DsRoute.current(route)){
        selected.value = true;
}

function handleMiddleClick(event: MouseEvent){
    if(event.button === 1){
        goToRoute(selected_route,routeParam,true);
    }
}
</script>

<template>
  <button @click="goToRoute(selected_route,routeParam)" @mousedown="handleMiddleClick" v-if="selected_route"
          :class="['flex pl-3 pr-2 py-1 transition-all group duration-250',{
              'text-white bg-primary-500':selected && !category,
              'bg-primary-400 text-white':selected && category,
          'cursor-pointer hover:bg-primary-200 hover:text-primary-950':!selected
          }]">
        <i :class="[icon,'text-lg mr-3']"></i>

        <span class="flex w-full overflow-hidden text-nowrap">
            <span class="my-auto truncate"><slot>{{ name }}</slot></span>
            <i :class="['fa-solid text-lg ml-auto fa-angle-right opacity-0 transition duration-700 group-hover:opacity-100 ']"></i>
        </span>
  </button>
</template>

<style scoped>
</style>
