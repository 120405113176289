<script setup lang="ts">
import {DsWindow, window_list, window_minimized_list} from "~/helper/windowManager";
import {onMounted, ref, watch} from "vue";
import {DsNotification, is_listening_for_notifications} from "~/helper/Navigator/DsNotification";
import {Head, router} from "@inertiajs/vue3";
import {useCookies} from "@vueuse/integrations/useCookies";
import {full_title, setTitleSuffix} from "~/composable/layout/title";
import {initLoader} from "~/composable/layout/loader";
import {DsUser} from "~/helper/User/DsUser";
import {sidebar_open} from "~/composable/layout/sidebar";
import Menu_user from "~templates/layouts/menu_user.vue";
import {env} from "~/utils.js";
import sidebar from "~templates/layouts/sidebar/sidebar.vue";
import PageSpinner from "~templates/components/spinner/page-spinner.vue";
import usePageSpinner from "~/composable/layout/usePageSpinner";
import {useIdle} from '@vueuse/core'
import {ticket_select} from "~/pages/tickets/tickets";
import {useToastAutomaticHandler} from "~/helper/Toast";
import {screenSize} from "~/helper/Navigator/ResponsiveHelper";
import DsModalConfirm from "~templates/components/pophover/ds-modal-confirm.vue";

defineProps({
    topMenuColor: {
        type: String,
        default: 'bg-white'
    }
});

const cookies = useCookies()

setTitleSuffix('');

initLoader();

const sidebarKey = ref(0);
router.on('navigate', (event) => {
    sidebarKey.value++;
    if (!is_listening_for_notifications.value) {
        DsNotification.listen();
    }
});

useToastAutomaticHandler();
const notification_loaded = ref(false);

function loadNotification() {
    if (notification_loaded.value) {
        return;
    }
    notification_loaded.value = true;
    DsNotification.loadAlreadyRegisterNotifications();
    if (!("Notification" in window)) {
        console.warn("💥 This browser does not support desktop notification");
        return
    }
    if (Notification.permission !== "denied" && Notification.permission === "default") {
        Notification.requestPermission();
    }
}

const {spinner, startSpinner} = usePageSpinner();

const {idle} = useIdle(60_000 * 60 * 4) // 4h
watch(idle, (value) => {
    if (value) {
        startSpinner();
        window.location.reload();
    }
})


onMounted(() => {
    loadNotification();
})


</script>

<template>
    <div>
        <ds-modal-confirm></ds-modal-confirm>
        <component v-for="window in window_list" :is="DsWindow.getComponent(window.slug)"
                   v-bind="{slug:window.slug,name:window.name,...DsWindow.getProps(window.slug)}"/>
        <page-spinner v-model="spinner"/>
        <div id="app">
            <Head :title="full_title">
            </Head>
            <div class="h-screen flex flex-row" :class="[ ticket_select > 0 ? 'overflow-hidden' : '' ]">
                <sidebar :key="sidebarKey"/>

                <div class="bg-gray-100 flex-1">
                    <header id="header"
                            :class="['w-full flex justify-center px-4 xl:px-6 border-b border-gray-300 items-center',topMenuColor]"
                            v-if="!DsUser.isAuth()">


                        <img src="/images/logo/default.jpg" class="h-24 w-24"
                             alt="driftshop_logo"/>

                    </header>
                    <header id="header"
                            :class="['w-full border-b border-gray-300 print:hidden',topMenuColor]"
                            v-else>

                        <div class="flex flex-row items-center py-0.5">
                            <div class="flex items-center justify-center">

                                <i class="fa-solid fa-bars pl-4 text-xl cursor-pointer hover:text-primary-600 md:hidden"
                                   v-if="!$slots.sidebar"
                                   @click="sidebar_open =
                                    !sidebar_open"></i>
                                <a href="/" class="cursor-pointer xl:h-14 xl:w-14 w-10 h-10 mt-1 ">
                                    <img src="/images/logo/default.jpg" class=""
                                         alt="driftshop_logo"/>
                                </a>

                            </div>

                            <div class="flex gap-2">
                                <div class="rounded-full px-1.5 py-1 bg-red-500 text-white font-light text-[10px]"
                                     v-if="cookies.get('p_helper_DsService_cache') === false">
                                    NO CACHE
                                </div>

                                <div class="rounded-full px-2 py-1 bg-amber-500 text-white  text-[10px]"
                                     v-if="env === 'staging'">
                                    PRÉPROD
                                </div>
                                <div class="rounded-full px-2 py-1  bg-primary-500 text-white  text-[10px]"
                                     v-else-if="env === 'local'">
                                    DÉV
                                </div>
                                <div class="rounded-full px-2 py-1  bg-rose-600 text-white  text-[10px]"
                                     v-else-if="env !== 'production'">
                                    ???
                                </div>
                            </div>

                            <!--              <div class="grow flex items-center justify-center px-6">-->
                            <!--                <div class=" w-full max-w-2xl">-->
                            <!--                  &lt;!&ndash; Recherche&ndash;&gt;-->
                            <!--                </div>-->
                            <!--              </div>-->

                            <div class="ml-auto">
                                <div class="flex relative float-right items-center">
                                    <div class="relative text-sm h-full">
                                        <menu_user></menu_user>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div class="flex">
                        <div class="px-2 md:px-3 xl:px-6 md:py-4 py-2 grow ">
                            <slot></slot>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="fixed gap-4 bottom-0 right-5 flex">
            <div class="bg-white px-4 py-1 rounded-t-md border-x border-t border-gray-600 cursor-pointer group"
                 @click="DsWindow.toggleMinimizeWindow(window.slug)" v-for="window in window_minimized_list">
                <i class="fa-solid fa-x text-red-500 pr-2 hover:text-red-400"
                   @click.stop="DsWindow.closeWindow(window.slug)"> </i>
                <span>{{ window.name }}</span>
            </div>
        </div>

        <div class="mx-auto font-extrabold text-xl text-pink-600 fixed bottom-0 right-0 px-2 py-1 pointer-events-none"
             v-if="env === 'local'">
            {{ screenSize() }}
        </div>
    </div>
</template>

<style scoped>
</style>
