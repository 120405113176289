import './bootstrap';
import {createApp, h} from 'vue'
import {createInertiaApp} from '@inertiajs/vue3'
import defaultLayout from '~templates/layouts/defaultLayout.vue'
import VueDraggableResizable from 'vue-draggable-resizable'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import {is_mounted} from "./helper/App";
import * as Sentry from '@sentry/vue';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import {resolvePageComponent} from "laravel-vite-plugin/inertia-helpers";

createInertiaApp({
    resolve: name => {
        // @ts-ignore

        const page = resolvePageComponent(`../../templates/pages/${name}.vue`,import.meta.glob('../../templates/pages/**/*.vue'));
        page.then((module) => {
            module.default.layout = module.default.layout || defaultLayout
        });

        return page;
    },
    setup({el, App, props, plugin}) {
       const app = createApp({render: () => h(App, props)})

        Sentry.init({
            app: app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            tracesSampleRate: 1.0,
            replaysOnErrorSampleRate: 0.5,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
        });

       app.use(plugin)
            .use(FloatingVue)
            .use(Toast,{
                toastClassName: 'custom',
                bodyClassName: ['custom'],
                transition: "Vue-Toastification__fade",
                maxToasts: 4,
                newestOnTop: true,
                position: "bottom-right",
                // filterBeforeCreate: (toast, toasts) => {
                //     if (toasts.filter(
                //         t => t.type === toast.type
                //     ).length !== 0) {
                //         // Returning false discards the toast
                //         return false;
                //     }
                //     // You can modify the toast if you want
                //     return toast;
                // }
            })
            .component('vue-draggable-resizable', VueDraggableResizable)
            .mount(el);

        is_mounted.value = true;
    },
    progress: false,
})




